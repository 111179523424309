import { faCalculator,  faIdCard, faPlusMinus,  faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ShoppingCartIcon, UserGroupIcon, PresentationChartBarIcon, HeartIcon } from '@heroicons/react/24/outline'

function DadosPlataforma() {
    return (
        <Row className='dashboard-dadosplataforma-title '>
            <Col>
                <Row className='div-grafico'>
                <UserGroupIcon  className='icone-grafico' />
                    <h3 className='titulo-grafico'>Clientes</h3>
                    <h2 className='numero-grafico nanito'>14</h2>
                    
                </Row>
            </Col>
           
            <Col>
                <Row className='div-grafico'>
                <ShoppingCartIcon  className='icone-grafico' />
                    <h3 className='titulo-grafico'>Vendas</h3>
                    <h2 className='numero-grafico nanito'>5</h2>
                    
                </Row>
            </Col>

            <Col>
                <Row className='div-grafico'>
                <PresentationChartBarIcon   className='icone-grafico' />
                    <h3 className='titulo-grafico'>Médias</h3>
                    <h2 className='numero-grafico nanito'>6</h2>
                    
                </Row>
            </Col>

            <Col>
                <Row className='div-grafico'>
                <HeartIcon   className='icone-grafico' />
                    <h3 className='titulo-grafico'>Satisfação</h3>
                    <h2 className='numero-grafico nanito'>5</h2>
                    
                </Row>
            </Col>
           
           

        </Row>
    )
}

export default DadosPlataforma