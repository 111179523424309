import React from 'react'
import { Button, Card, Col, Row, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faAward, faCartPlus, faPiggyBank, faStore, faUsers} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-regular-svg-icons'
import { BeakerIcon} from '@heroicons/react/24/solid'
import { UserIcon, UserGroupIcon, BuildingStorefrontIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import One from '../../assets/buttons/carinha.png'
import Two from '../../assets/buttons/2.png'
import Three from '../../assets/buttons/porco.png'
import Four from '../../assets/buttons/carrinho.png'
import Five from '../../assets/buttons/ponto.png'
import Six from '../../assets/buttons/caixdefa.png'

function DashboardButtons(props) {
    return (
        <Row className='dashboard-buttons-rows mt-2'>

            <Col>
                <Card className='dashboard-card dashboard-card-caixamenor' onClick={props.showCadastroModal}>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito' >Cadastro</Button>
                    </Card.Body>
                </Card>
            </Col>

            <Col>
                <Card className='dashboard-card dashboard-card-caixamenor' onClick={() => window.location.href = "/cadastradosClientes"}>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito' >Consumidores</Button>
                    </Card.Body>
                </Card>
            </Col>

            <Col>
                <Card className='dashboard-card dashboard-card-caixamenor' onClick={() => window.location.href = "/pontuarCliente"}>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito' >Pontuar</Button>
                    </Card.Body>
                </Card>
            </Col>

            <Col>
                <Link to="/caixa" >
                <Card className='dashboard-card dashboard-card-caixamenor'>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito'>Caixa</Button>
                    </Card.Body>
                </Card></Link>
            </Col>

          {/* 
             <Col >
                <Card className='dashboard-card' onClick={props.showCadastroModal}>
                    <Card.Body>
                        <div className='dashboard-card-icon'>
                        <UserIcon style={{ width: '80px'}} />
                        </div>
                        <Button className='dashboard-button-novousuario nanito' >Cadastro</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card ' onClick={() => window.location.href = "/cadastradosClientes"}>
                    <div className='dashboard-card-icon '>
                    <UserGroupIcon  style={{ width: '80px'}} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito' >Consumidores</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card' onClick={() => window.location.href = "/pontuarCliente"}>
                    <div className='dashboard-card-icon '>
                    <CurrencyDollarIcon style={{ width: '80px'}} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito' >Pontuar</Button>
                    </Card.Body>
                </Card>
            </Col>

            <Col>
                <Link to="/caixa" ><Card className='dashboard-card'>
                    <div className='dashboard-card-icon '>
                    <BuildingStorefrontIcon  style={{ width: '80px'}} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario nanito'>Caixa</Button>
                    </Card.Body>
                </Card></Link>
            </Col>
           
         <Col  xs lg="3">
                <Card className='dashboard-card' onClick={() => window.location.href = "/pontuarCliente"}>
                    <div className='dashboard-card-icon '>
                        <Image src={Three} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario' >Pontuar</Button>
                    </Card.Body>
                </Card>
            </Col>
           */}
           
        </Row>
    )
}

export default DashboardButtons