import React from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ConfigMsgColuna1 from '../../components/ConfigMensagem/ConfigMsgColuna1'
import ConfigMsgColuna2 from '../../components/ConfigMensagem/ConfigMsgColuna2'


function Whatsapp() {
  return (
    <GenericBoxView 
    icon={<FontAwesomeIcon icon={faEnvelope} size='2x' />} 
    title={'Configuração de Mensagem'} 
    titleBox={'Configurações de envio de mensagem automatica'} 
    coluna1={<ConfigMsgColuna1/>} 
    coluna2={<ConfigMsgColuna2/>}/>
  )
}

export default Whatsapp