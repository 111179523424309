import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import axios from 'axios';
import { format } from 'date-fns';

function PontosDistribuidos() {
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar o carregamento
  const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/pontos/`,
          { headers }
        );

        const sortedData = response.data.sort(
          (a, b) => b.cliente_id - a.cliente_id
        ); // Ordena por cliente_id decrescente
        setClientes(sortedData);
      } catch (error) {
        console.error('Erro ao buscar contate o administrador:', error);
      } finally {
        setIsLoading(false); // Finaliza o carregamento
      }
    };

    fetchData();
  }, []);

  const totalPages = Math.ceil(clientes.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <br />
      <Card className="cardPontosDistribuidos">
        <Card.Title className="cadastroNovoscliente-card-title">
          <h4>Pontos Distribuídos</h4>
        </Card.Title>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Qtd Pontos</th>
                    <th>Cashback</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((cliente) => (
                    <tr key={cliente.id}>
                      <td>{cliente.nome}</td>
                      <td>{cliente.pontos}</td>
                      <td>{cliente.cashback}</td>
                      <td>{cliente.email}</td>
                      <td>{cliente.telefone}</td>
                      <td>
                        {format(new Date(cliente.dataCadastro), 'dd/MM/yyyy')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Paginação */}
              <div className="d-flex justify-content-center">
                <ul className="pagination">
                  {/* Botão Anterior */}
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <Button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Anterior
                    </Button>
                  </li>

                  {/* Lógica para limitar a exibição a 6 páginas */}
                  {Array.from({ length: Math.min(6, totalPages) }).map(
                    (_, index) => {
                      const startPage = Math.max(1, currentPage - 3);
                      const endPage = Math.min(totalPages, startPage + 5);
                      const page = startPage + index;

                      if (page > totalPages) return null;

                      return (
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? 'active' : ''
                          }`}
                        >
                          <Button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      );
                    }
                  )}

                  {/* Botão Próxima */}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <Button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Próxima
                    </Button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default PontosDistribuidos;
