// ProgressCard.js
import React from 'react';
import './ProgressCard.css';

const ProgressCard = ({ icon, title, subtitle, currentValue, maxValue }) => {
    const strokeDasharray = 301.635;
    const strokeDashoffset = strokeDasharray - (currentValue / maxValue) * strokeDasharray;

    return (
        <div className="card mb-4 progress-card">
            <div className="progress-card-body justify-content-between d-flex flex-row align-items-center">
                <div>
                    <i className={`${icon} progress-card-icon align-text-bottom d-inline-block`}></i>
                    <div>
                        <p className="progress-card-lead">{title}</p>
                        <p className="progress-card-text-small">{subtitle}</p>
                    </div>
                </div>
                <div>
                    <div
                        role="progressbar"
                        className="progress-card-circle position-relative"
                        aria-valuenow={currentValue}
                        aria-valuemax={maxValue}
                        data-show-percent="false"
                    >
                        <svg viewBox="0 0 100 100" style={{ display: 'block', width: '100%' }}>
                            <path
                                d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
                                stroke="rgba(255,255,255,0.2)"
                                strokeWidth="4"
                                fillOpacity="0"
                            ></path>
                            <path
                                d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
                                stroke="white"
                                strokeWidth="4"
                                fillOpacity="0"
                                style={{ strokeDasharray, strokeDashoffset }}
                            ></path>
                        </svg>
                        <div
                            className="progress-card-text"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                padding: 0,
                                margin: 0,
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                            }}
                        >
                            {currentValue}/{maxValue}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressCard;
