
import React from 'react'
import {  Card } from 'react-bootstrap'
import GenericTabBoxViewOneC from './GenericTabBoxViewOneC'

function GenericBoxViewOneC(props) {
  return (
    <>
     {/* <div className='icon-text text-white'>
        {props.icon}<h3 className='ms-3'> {props.title}</h3>
      </div>
      <hr></hr>
      */}
      <br></br>
      <Card>
        <Card.Title className='cadastroNovoscliente-card-title'><h4>{props.titleBox}</h4></Card.Title>
          <GenericTabBoxViewOneC coluna1={props.coluna1} title={props.title} />
      </Card>
    </>
  )
}

export default GenericBoxViewOneC