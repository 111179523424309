import React from 'react';
import { Image } from 'react-bootstrap';
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import './NavbarCaixa.css'; // Arquivo CSS separado
import Logo from '../../assets/logo.png'
import Avatar from '../../components/Avatar/Avatar'
import "./iconsmind-s/css/iconsminds.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const NavbarCaixa = () => {
    var role = sessionStorage.getItem('role')
  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left" >
        
      
        <DropdownButton
        id="dropdown-clientes"
        title={
            <>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
        </>
        }
        drop="end"
        variant="link"
        className="dropdown-clientes"
        >
            {role === '1' && (
        <Dropdown.Item href="/">
            <i className="iconsminds-dashboard"></i> Painel Administrativo
        </Dropdown.Item>
        )}

<Dropdown.Item href="https://clienteprime.com.br/pontoDigital">
            <i className="iconsminds-check"></i> Ponto Digital
        </Dropdown.Item>
        </DropdownButton>



        
      </div>

      <a className="navbar-logo" >
      <Image className='logo-center' src={Logo}/>
      </a>

      <div className="navbar-right">
        

        {/* User Menu */}
        <Avatar/>
      </div>
    </nav>
  );
};

export default NavbarCaixa;
