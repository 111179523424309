import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { Card, Table } from 'react-bootstrap'

function StatusIndicacoes() {
    return (
        <>
          <br></br>
            <Card>
                <Card.Title className='cadastroNovoscliente-card-title'><h4>Resultados</h4></Card.Title>
                <Card.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Qtd Indicados</th>
                                <th>Qtd Cadastrados</th>
                                <th>Qtd Compraram</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}

export default StatusIndicacoes