import React, { useState } from 'react'
import LogoGenerica from '../../assets/logo2.png'
import LogoCP from '../../assets/logo-cp.png'
import './Login.css'
import axios from 'axios'
import { Alert } from 'react-bootstrap'

function Login() {

    const [usuario, setUsuario] = useState('')
    const [password, setPassword] = useState('')
    const [loginOrReset, setLoginOrReset] = useState(true)
    const [error, setError] = useState(false)

    
    const handleSubmit = () => {
        axios.post(`${process.env.REACT_APP_URLBK}/api-token-auth/`, { "username": usuario, "password": password }, {
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",

        })
            .then(response => {

                sessionStorage.setItem("token", response.data['token'])
                sessionStorage.setItem("username", response.data['username'])
                sessionStorage.setItem("id", response.data['id'])
                sessionStorage.setItem("role", String(response.data['role']))
                if (String(response.data['role']) === "1") {
                    window.location = "/"
                } else if (String(response.data['role']) === "4") {
                    alert('Vendedor Não tem acesso a sistema de caixa/adminstração')
                } else {
                    window.location = "/caixa"
                }
                // Faça o processamento necessário com a resposta
            })
            .catch(error => {
                setError(true)
                // Lida com erros da requisição
            });
    }


    const handleRecovery =() => {
        setLoginOrReset(false);
    }

    return (
        <div className='container-login'>
            <div className='logo-login-cp-header'>
                <img src={LogoCP} className='logo-login-cp-img' />
            </div>
            <div className='login-desktop-div'>
                <div className='login-desktop-prime'>
                    <h2>"Venha Viver a Experiência Prime"</h2>
                </div>
                <div className='login-desktop-prime-2'>
                    <div>
                        <img src={LogoGenerica} className='logo-login-img' />
                    </div>
                    <div className="mt-5 card-form-login">
                        <div>
                            {error === true ?
                                < Alert key="danger" variant="danger">
                            Usuario e/ou Senha Invalida!
                            </Alert> : ""
                            }
                    {loginOrReset === true ? (
                        <>
                        <h4>Acesse sua conta</h4>

                        <div className="form-group">
                            <label htmlFor="nickName">Usuário</label>
                            <input
                                type="text"
                                className="form-control mb-3"
                                id="nickName"
                                value={usuario}
                                onChange={e => setUsuario(e.target.value)}
                            />
                        </div>
                        

                        <div className="form-group">

                            <label htmlFor="password">Senha</label>
                            <input
                                type="password"
                                className="form-control mb-3"
                                id="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn button-prime-orange-login" onClick={handleSubmit}>Entrar</button>
                        <a onClick={() => setLoginOrReset(false)}><p>Esqueceu sua senha?</p></a>
                        </>) : (<>
                            <h4>Recupere sua conta</h4>

                        <div className="form-group">
                            <label htmlFor="nickName">Email</label>
                            <input
                                type="email"
                                className="form-control mb-3"
                                id="nickName"
                                value={usuario}
                                required
                                onChange={e => setUsuario(e.target.value)}
                            />
                            <button type="button" className="btn button-prime-orange-login" onClick={handleRecovery}>Recuperar</button>
                            <button type="button" className="btn button-prime-orange-login" onClick={() => setLoginOrReset(true)}>Voltar</button>
                        </div>
                            
                        </>)}

                    </div>
                </div>
                </div>

            </div>

        </div >
    )
}

export default Login