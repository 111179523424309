import React, { useState } from "react";
import "./Menu.css";
import "./iconsmind-s/css/iconsminds.css";
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const MainMenu = () => {
  return (
    <div className="main-menu">
      <br />
      <br />
      <br />
      <br />

 
          <div className="menu-principal">
            <ul className="list-unstyled">

              <li>
                <a href="/cliente/areadocliente" className="menu-item">
                  <i className="iconsminds-home"></i>
                  <span>Home</span>
                </a>
              </li>

              
              <li>
                <a href="/cliente/indiqueUmAmigo" className="menu-item">
                  <i className="iconsminds-affiliate"></i>
                  <span>Indique um Amigo</span>
                </a>
              </li>



              
              <li>
                <a href="/cliente/pesquisa" className="menu-item">
                  <i className="iconsminds-check"></i>
                  <span>Pesquisa de satisfação</span>
                </a>
              </li>

              
              <li>
                <a href="/cliente/contato" className="menu-item">
                  <i className="iconsminds-consulting"></i>
                  <span>Contato</span>
                </a>
              </li>

              
              <li>
                <a href="/cliente/contato" className="menu-item">
                  <i className="iconsminds-gear-2"></i>
                  <span>Conta</span>
                </a>
              </li>

              
              <li>
                <a href="/cliente" className="menu-item">
                  <i className="iconsminds-power-2"></i>
                  <span>Logout</span>
                </a>
              </li>

             

            </ul>
          </div>
      
    </div>
  );
};

export default MainMenu;
