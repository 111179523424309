import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import InputMask from 'react-input-mask';

function EditarCliente({ cpf, onClose }) {
  const initialClienteData = {
    nome: '',
    cpf: '',
    sexo: '',
    data_de_nascimento: '',
    email: '',
    telefone: '',
  };


  const convertDateToInputFormat = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`; // Retorna no formato "yyyy-MM-dd"
  };

  
  const [clienteData, setClienteData] = useState(initialClienteData);
  const [isLoading, setIsLoading] = useState(false);

  // Atualiza os dados do cliente ao receber o CPF
  useEffect(() => {
    if (cpf) {
      fetchClienteData(cpf);
    }
  }, [cpf]);

  const fetchClienteData = async (cpf) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      };
  
      const response = await axios.get(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta?documento=${cpf}`,
        { headers }
      );
  
      console.log('Dados recebidos da API:', response.data);
  
      // Verificar se há dados no array
      if (response.data && response.data.length > 0) {
        const cliente = response.data[0]; // Pega o primeiro item do array
  
        // Mapear os campos recebidos para o formato esperado no estado
        setClienteData({
          nome: cliente.nome || '',
          cpf: cliente.cpf || '',
          sexo: cliente.sexo || '',
          data_de_nascimento: cliente.dataNascimento
            ? convertDateToInputFormat(cliente.dataNascimento)
            : '',
          email: cliente.email || '',
          telefone: cliente.telefone || '',
        });
      } else {
        alert('Nenhum cliente encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar cliente:', error);
      alert('Erro ao buscar cliente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/atualizar`, // URL para atualização
        clienteData,
        { headers }
      );

      alert('Cliente atualizado com sucesso!');
      if (onClose) onClose(); // Fecha o modal após a atualização, se fornecido
    } catch (error) {
      console.error('Erro ao atualizar o cliente:', error);
      alert('Erro ao atualizar o cliente.');
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          {isLoading ? (
            <p>Carregando dados do cliente...</p>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="nome">
                <Form.Label className="bold">Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  value={clienteData.nome || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="cpf">
                <Form.Label className="bold">CPF</Form.Label>
                <InputMask
                  mask="999.999.999-99"
                  maskChar=" "
                  className="form-control"
                  name="cpf"
                  value={clienteData.cpf || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  disabled // CPF não deve ser editável
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="sexo">
                <Form.Label className="bold">Sexo</Form.Label>
                <Form.Control
                  as="select"
                  name="sexo"
                  value={clienteData.sexo || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Selecione o Sexo</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outros">Outros</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="data_de_nascimento">
                <Form.Label className="bold">Data de Nascimento</Form.Label>
                <Form.Control
                  type="date"
                  name="data_de_nascimento"
                  value={clienteData.data_de_nascimento || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  required
                  max={new Date().toISOString().split('T')[0]} // Impede datas futuras
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="bold">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  value={clienteData.email || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="telefone">
                <Form.Label className="bold">Telefone com DDD</Form.Label>
                <InputMask
                  mask="(99) 99999-9999"
                  maskChar=" "
                  className="form-control"
                  name="telefone"
                  placeholder="Telefone com DDD"
                  value={clienteData.telefone || ''} // Garante que o valor seja string
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Atualizar
              </Button>
            </Form>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default EditarCliente;
