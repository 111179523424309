import React, { useState } from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ConsultaColuna1 from '../../components/ConsultaClientes/ConsultaColuna1'
import ConsultaColuna2 from '../../components/ConsultaClientes/ConsultaColuna2'
import { Card, Table, Button, Modal } from 'react-bootstrap'
import axios from 'axios';
import { parseISO, format } from 'date-fns';
import PontuarClienteModel from '../../components/modelsGenericos/PontuarCliente'
import ResgatarClienteModel from '../../components/modelsGenericos/ResgatarCliente'

function ClientesCadastrados() {
    const [clientes, setClientes] = useState([]);
    const [showPontuar, setShowPontuar] = React.useState(false);
    const [showResgatar, setShowResgatar] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;
    const [filtros, setFiltros] = useState({
        nome: '',
        email: '',
        documento: '',
        telefone: '',
        todos: '',
    });


    const totalPages = Math.ceil(clientes.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
    

      const fetchClientes = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };
    
            let response;
    
            if (filtros.todos) {
                response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta`, { headers });
            } else {
                response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta`, {
                    headers,
                    params: filtros,
                });
            }
    
            console.log('Dados da API:', response.data);
    
            if (Array.isArray(response.data)) {
                setClientes(response.data);
            } else {
                console.error('A resposta da API não é uma lista:', response.data);
                setClientes([]);
            }
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
            setClientes([]);
        }
    };
    

    

    var showPontuarModal = () => {
        setShowPontuar(true);
    }
    var showResgatarModal = () => {
        setShowResgatar(true);
    }


    const handleClose = () => {
        setShowPontuar(false);
        setShowResgatar(false);
    };
    return (
        <>
            <GenericBoxView
                icon={<FontAwesomeIcon icon={faSearch} size='2x' />}
                title={'Consultar Clientes'}
                titleBox={'Consultar Clientes'}
                coluna1={<ConsultaColuna1 />}
                coluna2={<ConsultaColuna2 onFiltrosChange={setFiltros} onSearch={fetchClientes}  />} />
            <hr />
            <Card>
                <Card.Title className='cadastroNovoscliente-card-title'><h4>Resultados</h4></Card.Title>
                <Card.Body>
                <Table striped bordered hover>
  <thead>
    <tr>
      <th>Consumidor</th>
      <th>E-mail</th>
      <th>Telefone</th>
      <th>Sexo</th>
      <th>Data de Nascimento</th>
      <th>Data Cadastro</th>
      <th>Ações</th>
    </tr>
  </thead>
  <tbody>
    {currentItems.length === 0 ? (
      <tr>
        <td colSpan="7" className="text-center">
          Nenhum cliente encontrado.
          <br />
          <br />
          <Button
            variant="primary"
            onClick={() => window.location.href = '/cadastroCliente'}
            className="ml-3"
          >
            Cadastrar Cliente
          </Button>
        </td>
      </tr>
    ) : (
      currentItems.map((cliente) => (
        <tr key={cliente.cliente_id}>
          <td>{cliente.nome}</td>
          <td>{cliente.email}</td>
          <td>{cliente.telefone}</td>
          <td>{cliente.sexo}</td>
          <td>{cliente.dataNascimento}</td>
          <td>{format(parseISO(cliente.dataCadastro), 'dd/MM/yyyy')}</td>
          <td>
            <Button onClick={showPontuarModal}>Pontuar</Button>{' '}
            <Button variant="danger" onClick={showResgatarModal}>Resgatar</Button>
          </td>
        </tr>
      ))
    )}
  </tbody>
</Table>

              {/* Paginação */}
<div className="d-flex justify-content-center">
  <ul className="pagination">
    {/* Botão Anterior */}
    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
      <Button
        className="page-link"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Anterior
      </Button>
    </li>

    {/* Lógica para limitar a exibição a 6 páginas */}
    {Array.from({ length: Math.min(6, totalPages) }).map((_, index) => {
      // Calcula a página inicial e final visíveis
      const startPage = Math.max(1, currentPage - 3);
      const endPage = Math.min(totalPages, startPage + 5);

      const page = startPage + index;
      if (page > totalPages) return null;

      return (
        <li
          key={page}
          className={`page-item ${page === currentPage ? 'active' : ''}`}
        >
          <Button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </Button>
        </li>
      );
    })}

    {/* Botão Próxima */}
    <li
      className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
    >
      <Button
        className="page-link"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Próxima
      </Button>
    </li>
  </ul>
</div>

              

                </Card.Body>
            </Card>
            <Modal show={showPontuar} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PontuarClienteModel handleClose={handleClose} />
                </Modal.Body>
            </Modal>
            <Modal show={showResgatar} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResgatarClienteModel handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClientesCadastrados