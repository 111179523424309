import React from 'react'
import { Col, Container, Row, Modal, Card } from 'react-bootstrap'
import './Dashboard.css'
import DashboardButtons from '../../components/dashboard/DashboardButtons'
import DadosPlataforma from '../../components/dashboard/DadosPlataforma'
import DashboardCards from '../../components/dashboard/DashboardCards'
import ProgressCard from '../../components/dashboard/ProgressCard'
import { GraficoPlataforma, GraficoPlataforma2 } from '../../components/dashboard/GraficoPlataforma'
import CadastroClienteModel from '../../components/modelsGenericos/CadastroCliente'
import PontosDistribuidos from '../../views/Cliente/PontosDistribuidos';

function Dashboard() {
    const [showCadastro, setShowCadastro] = React.useState(false);

    var showCadastroModal = () => {
        setShowCadastro(true);
    }

    const handleClose = () => {
        setShowCadastro(false);
    };

    return (
        <Container fluid className='container-dashboard '>
            <div className='dashboard-div-title'>
                <h3>Dashboard</h3>
            </div>

            
          <br></br><br></br>
            
            <Row className='dashboard-graficos'>
                <Col xs={7}>
                    
                    <Card className='dashboard-card-model DashboardCards-principal'>
                    <DashboardCards />
                    </Card>
                </Col>
                <Col xs={5}>
                <Card className='dashboard-card-model DashboardCards-principal'>
                    
        <br></br>
                <ProgressCard 
    icon="iconsminds-male"
    title="0"
    subtitle="Clientes cadastrados"
    currentValue={0}
    maxValue={0}
/>

                    </Card>
                    <br></br>
               
                </Col>

                <Col xs={12}>
                
                <PontosDistribuidos />

                   
               
                </Col>
            </Row>

            {/*<TabelasPlataforma /> */}
            <Modal show={showCadastro} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CadastroClienteModel handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Dashboard