import {faSave} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'


function ConfigMsgColuna2() {

  const [cashback, setCashback] = useState({
    ativar: false,
    percentual: '',
    centavos: false,
    minimo: '',
    limite: '',
  })

  

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
          { headers }
        );

        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          setCashback({
            ...data,
            ativar: !!data.ativar, // Converte para booleano
            centavos: !!data.centavos, // Converte para booleano
          });
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setCashback((prevCashback) => ({
      ...prevCashback,
      [name]: inputValue,
    }));
  };

  const handleFormSubmit = async (event) => {
    alert("Whatsapp Atualizado com sucesso!");
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5" >
        <Form.Label className='bold'>GANHA PONTO</Form.Label>
        <Form.Check type="checkbox" name='ganha_ponto' onChange={handleInputChange}  checked={cashback.ganha_ponto}/>
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>USAR PONTO</Form.Label>
        <Form.Check type="checkbox" name='usar_ponto' onChange={handleInputChange}  checked={cashback.usar_ponto}/>
      </Form.Group>
  
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>CADASTRO</Form.Label>
        <Form.Check type="checkbox" name='cadastro' onChange={handleInputChange}  checked={cashback.cadastro}/>
      </Form.Group>
    
      <div className='mb-3'>
        <Button className='m-1 usuarionovo-button-criar' type='submit'><FontAwesomeIcon icon={faSave} size='2x' /> Salvar</Button>
      </div>
    </Form>

  )
}

export default ConfigMsgColuna2