import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function ConfigMsgColuna1() {
  return (
    <>
      <div className='icon-text'>
        <FontAwesomeIcon icon={faEnvelope} size='4x' />
        <h3 className='ms-1'> Configuração</h3>
      </div>
      <br></br>
      <h5 style={{'font-size':'15px', 'marginBottom':'0.3rem'}}>Personalize as mensagens automáticas para manter contato direto com seus clientes!</h5>
      <p style={{'font-size':'12px', 'marginBottom':'0.1rem'}}>Com esta configuração, você poderá enviar notificações e atualizações importantes via SMS, e-mail ou WhatsApp, de forma prática e automática. Mantenha seus clientes informados e engajados com facilidade.</p>
    </>
  )
}

export default ConfigMsgColuna1