import React, { useState } from "react";
import "./Menu.css";
import "./iconsmind-s/css/iconsminds.css";
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const MainMenu = () => {
  return (
    <div className="main-menu">
      <br />
      <br />
      <br />
      <br />

 
          <div className="menu-principal">
            <ul className="list-unstyled">

              <li>
                <a href="/" className="menu-item">
                  <i className="iconsminds-dashboard"></i>
                  <span>Dashboard</span>
                </a>
              </li>


              <li>
                <a href="/caixa"  className="menu-item">
                  <i className="iconsminds-shop-4"></i>
                  <span>Caixa</span>
                </a>
              </li>

              <li>
                <a href="https://clienteprime.com.br/pontoDigital" className="menu-item">
                  <i className="iconsminds-check"></i>
                  <span>Ponto Digital</span>
                </a>
              </li>


              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-user"></i>
                      <span>Clientes</span>
                    </>
                  }
                  drop="end"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                  <Dropdown.Item href="/cadastroCliente"><i className="iconsminds-add-user"></i> Cadastro</Dropdown.Item>
                  <Dropdown.Item href="/pontuarCliente"><i className="iconsminds-add-user"></i> Pontuar</Dropdown.Item>
                  <Dropdown.Item href="/cadastradosClientes"><i className="iconsminds-conference"></i> Clientes Cadastrados</Dropdown.Item>
                  <Dropdown.Item href="/pontosDistribuidos"><i className="iconsminds-heart"></i> Pontos Distribídos</Dropdown.Item>
                  <Dropdown.Item href="/aniversariantesClientes"><i className="iconsminds-gift-box"></i> Aniversariante do Mês</Dropdown.Item>
                  <Dropdown.Item href="/statusIndicacoes"><i className="iconsminds-user"></i> Status de Indicação</Dropdown.Item>
                  <Dropdown.Item href="/cliente" target="_blank"><i className="iconsminds-user"></i> Página do Cliente</Dropdown.Item>
                </DropdownButton>
              </li>


              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-target-market"></i>
                      <span>Vendedor</span>
                    </>
                  }
                  drop="end"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item href="/vendedor"><i className="iconsminds-user"></i> Vendedores</Dropdown.Item>
                <Dropdown.Item href="/vendedor/cadastro"><i className="iconsminds-add-user"></i> Cadastrar Vendedor</Dropdown.Item>
                <Dropdown.Item href="/vendedor/fila"><i className="iconsminds-target-market"></i> Vendedor da Vez</Dropdown.Item>
                </DropdownButton>
              </li>


              
              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-target-market"></i>
                      <span>Recursos</span>
                    </>
                  }
                  drop="end"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item href="/recursos/cashback"><i className="iconsminds-money-bag"></i> Cashback</Dropdown.Item>
                <Dropdown.Item href="/vendedor/cadastro"><i className="iconsminds-male-female"></i> Indicações</Dropdown.Item>
                <Dropdown.Item href="/recursos/registro_de_pontos"><i className="iconsminds-target-market"></i> Ponto Digital</Dropdown.Item>
                <Dropdown.Item href="/recursos/configuracao_mensagem"><i className="iconsminds-email"></i> Configuração de Mensagem</Dropdown.Item>
                <Dropdown.Item href="/recursos/whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="FontAwesomeIcon" /> Whatsapp</Dropdown.Item>
                </DropdownButton>
              </li>

              
              
              <li>
                <DropdownButton
                  id="dropdown-clientes"
                  title={
                    <>
                      <i className="iconsminds-gear-2"></i>
                      <span>Configuração</span>
                    </>
                  }
                  drop="end"
                  variant="link"
                  className="menu-item  dropdown-clientes"
                >
                <Dropdown.Item href="/configuracao/conta"><i className="iconsminds-user"></i> Conta</Dropdown.Item>
                <Dropdown.Item href="/configuracao/usuario"><i className="iconsminds-conference"></i> Usuários</Dropdown.Item>
                </DropdownButton>
              </li>


            

            </ul>
          </div>
      
    </div>
  );
};

export default MainMenu;
