import React from 'react'
import { Col, Container, Row, Modal, Card } from 'react-bootstrap'
import './Dashboard.css'
import DadosPlataforma from '../../components/dashboard/DadosPlataforma'
import { GraficoPlataforma, GraficoPlataforma2 } from '../../components/Graficos/GraficoPlataforma'
import { PieChart } from '../../components/Graficos/PieChart'
import CadastroClienteModel from '../../components/modelsGenericos/CadastroCliente'

function PainelMetricas() {
    const [showCadastro, setShowCadastro] = React.useState(false);

    var showCadastroModal = () => {
        setShowCadastro(true);
    }

    const handleClose = () => {
        setShowCadastro(false);
    };

    return (
        <Container fluid className='container-dashboard '>
            <div className='dashboard-div-title'>
                <h3>Gráficos2</h3>
            </div>
            <br></br>
            <Row className='dashboard-graficos'>

                <Col xs={7}>
                    <Card className='dashboard-card-model'>
                        <DadosPlataforma />
                    </Card>
                    <br></br>
                    <Card className='dashboard-card-model'>
                    <GraficoPlataforma />
                    </Card>
                </Col>

                <Col xs={5}>
                <Card className='dashboard-card-model model-vh'>
                    <GraficoPlataforma2 />
                    </Card>
                </Col>

                <Col xs={6}>
                    <Card className='dashboard-card-model model-vh'>
                        <PieChart  />
                    </Card>
                </Col>

            </Row>
            {/*<TabelasPlataforma /> */}
            <Modal show={showCadastro} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CadastroClienteModel handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default PainelMetricas