// DashboardCards.js
import React from 'react';
import './DashboardCardsPcliente.css';
import "../Estrutura/iconsmind-s/css/iconsminds.css";

const DashboardCardPcliente = ({ icon, title, count, dinheiro }) => {
    return (
        <li className="glide__slide">
            <a href="#" className="card">
                <div className="card-body text-center">
                    <i className={icon}></i>
                    <p className="card-text mb-0">{title}</p>
                    <p className="lead text-center">
                    {dinheiro == 0 ? '' : "R$ "}{count}
                    </p>
                </div>
            </a>
        </li>
    );
};

const DashboardCardsPcliente = ({title1,title2,currentValue1,currentValue2}) => {
    const cardsData = [
        { icon: 'iconsminds-diamond', title: title1, count: currentValue1, dinheiro: 0 },
        { icon: 'iconsminds-dollar', title: title2, count: currentValue2, dinheiro: 1 },
    ];

    return (
        <div className="icon-cards-row">
            <div className="glide dashboard-numbers">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {cardsData.map((card, index) => (
                            <DashboardCardPcliente key={index} {...card} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DashboardCardsPcliente;
