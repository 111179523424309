import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { ShoppingCartIcon, UserGroupIcon, PresentationChartBarIcon, BanknotesIcon, PercentBadgeIcon } from '@heroicons/react/24/outline'
import DashboardCardsPcliente from '../../components/dashboard/DashboardCardsPcliente'
import './areadocliente.css'
import "../../components/Estrutura/iconsmind-s/css/iconsminds.css";

function AreaDoCliente() {
  const [historicos, setHistoricos] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;

  const fetchHistoricos = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const cpf = sessionStorage.getItem('cpf');
      const headers = {
        'Content-Type': 'application/json',
      };
      var dados = {
        "cpf": cpf,
        "token": token,
      }
      const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/areacliente/extrato/`, dados, { headers }); // Substitua com a URL correta
      console.log(response.data)
      setHistoricos(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do histórico:', error);
    }
  };
  const fetchSaldo = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const cpf = sessionStorage.getItem('cpf');
      const headers = {
        'Content-Type': 'application/json',
      };
      var dados = {
        "cpf": cpf,
        "token": token,
      }
      const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/areacliente/saldo/`, dados, { headers }); // Substitua com a URL correta
      setSaldo(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do histórico:', error);
    }
  };
  useEffect(() => {
    fetchHistoricos();
    fetchSaldo();
  }, [])

  const totalPages = Math.ceil(historicos.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = historicos.slice(indexOfFirstItem, indexOfLastItem);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 

  return (
    <div className='container-pc-cliente'>
      <br></br>
      <br></br>
      <br></br>
      <div className='areadocliente'>

      <Card className='card-extrato sem-scroll'>
   
        {saldo.map((saldo) => (
    
       
        <DashboardCardsPcliente 
    icon="iconsminds-male"
    title1="Pontos"
    title2="Cashback"
    currentValue1={saldo.pontos}
    currentValue2={saldo.cashback}
/>
//saldo.pontos
//saldo.cashback

      
 
  ))}
      
      </Card>

      

      <Card className='sem-scroll card-como-funciona card-info'>
        <Card.Title className='m-1'>
          {/*  <h1 className='mb-2'><strong>Como Funciona?</strong></h1>*/}
          <Row>
            <Col className='col-3'>
              <i className='iconsminds-shopping-bag'></i>
            </Col>
            <Col>
              <h4 className='mb-3'><strong>Compre e Ganhe</strong></h4>
              <p>Aproveite suas compras ao máximo! Identifique-se nas transações e desfrute de vantagens exclusivas. A cada R$1 gasto, você acumula 1 ponto, que pode ser utilizado em benefícios incríveis.</p>
            </Col>
          </Row>


        </Card.Title>
      </Card>
      
      <Card className='card-extrato card-table'>
        <Card.Title>
          <h1><strong>Extrato</strong></h1>
        </Card.Title>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ação</th>
                <th>Quantidade</th>
                <th>Data</th>
                <th>Empresa</th>
                <th>Expira em</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((historico) => (
                <tr key={historico.id}>
                  <td className={`tipo-${historico.tipo.toLowerCase().replace(' ', '-')}`}>{historico.tipo}</td>
                  <td>{historico.pontos}</td>
                  <td>{format(new Date(historico.at_create), 'dd/MM/yyyy HH:mm:ss')}</td>
                  <td>{historico.empresa}</td>
                  <td>01/12/2025</td>
                </tr>
              ))}
            </tbody>
          </Table>

            {/* Paginação */}
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''
              }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </Button>
            </li>
          </ul>
        </div>
        </Card.Body>
      </Card>
      </div>
    </div>
  )
}

export default AreaDoCliente