import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Switch from "react-switch";

function ConsultaColuna2({ onFiltrosChange, onSearch }) {
  const [isSwitchOn, setIsSwitchOn] = useState(false); // Estado para o Switch

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Verifica se é um checkbox e define o valor como checked (verdadeiro ou falso)
    const newValue = type === 'checkbox' ? checked : value;

    onFiltrosChange((prevFiltros) => ({
        ...prevFiltros,
        [name]: newValue,
    }));
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  
    onFiltrosChange((prevFiltros) => ({
      ...prevFiltros,
      todos: checked, // Atualiza apenas o campo 'todos' para true ou false
    }));
  };

  const handleSearch = () => {
    onSearch();
  };

  return (
      <Form>
        <Form.Group className="mb-3" controlId="switchTodos">
          <Form.Label className='bold'>Buscar todos os clientes:</Form.Label>
          <br></br>
          <Switch 
            checked={isSwitchOn}
            onChange={handleSwitchChange}
            name="todos"
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className='bold'>Nome:</Form.Label>
          <Form.Control
              type="text"
              placeholder="Nome do Cliente"
              name="nome"
              onChange={handleInputChange}
              disabled={isSwitchOn} // Desativa o campo se o Switch estiver ligado
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
          <Form.Label className='bold'>E-mail:</Form.Label>
          <Form.Control
              type="text"
              placeholder="E-mail do Cliente"
              name="email"
              onChange={handleInputChange}
              disabled={isSwitchOn}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
          <Form.Label className='bold'>Documento:</Form.Label>
          <InputMask
              mask="999.999.999-99"
              maskChar=" "
              className="form-control"
              name="documento"
              placeholder="Digite o CPF do cliente"
              onChange={handleInputChange}
              disabled={isSwitchOn}
              required
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
          <Form.Label className='bold'>Telefone:</Form.Label>
          <Form.Control
              type="text"
              placeholder="Telefone do Cliente"
              name="telefone"
              onChange={handleInputChange}
              disabled={isSwitchOn}
          />
        </Form.Group>
        
        <Button type="button" onClick={handleSearch}>
          <FontAwesomeIcon icon={faSearch} size='2x' /> Buscar
        </Button>
      </Form>
  );
}

export default ConsultaColuna2;
