import React from 'react'
import { Container} from 'react-bootstrap'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserNinja } from '@fortawesome/free-solid-svg-icons'
import VendedorDaVez from '../../components/Vendedores/VendedorDaVez'

function Acompanhamento_Fila() {
  return (
    <Container fluid className='container-dashboard '>
    <div className='dashboard-div-title'>
              <h4>Fila Vendedor</h4>
          </div>
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUserNinja} size='2x' />} 
    title={'Fila Vendedor'} 
    titleBox={'Fila vendedores da vez'} 
    coluna1={<VendedorDaVez/>} 
    />
    </Container>
  )
}

export default Acompanhamento_Fila