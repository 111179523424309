import React from 'react';
import { Image } from 'react-bootstrap';
import './Navbar.css'; // Arquivo CSS separado
import Logo from '../../assets/logo.png'

import AvatarCliente from '../../components/Avatar/AvatarCliente'

const NavbarCliente = () => {
  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left" >
        <a href="#" className="menu-button d-none d-md-block" style={{'padding-left':'15px'}}>
         
          <svg className="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>

        <a href="#" className="menu-button-mobile d-xs-block d-sm-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>

     

        
      </div>

      <a className="navbar-logo" >
      <Image className='logo-center' src={Logo}/>
      </a>

      <div className="navbar-right">
        

        {/* User Menu */}
        <AvatarCliente/>
      </div>
    </nav>
  );
};

export default NavbarCliente;
