// DashboardCards.js
import React from 'react';
import './DashboardCards.css';
import "../Estrutura/iconsmind-s/css/iconsminds.css";

const DashboardCard = ({ icon, title, count }) => {
    return (
        <li className="glide__slide">
            <a href="#" className="card">
                <div className="card-body text-center">
                    <i className={icon}></i>
                    <p className="card-text mb-0">{title}</p>
                    <p className="lead text-center">{count}</p>
                </div>
            </a>
        </li>
    );
};

const DashboardCards = () => {
    const cardsData = [
        { icon: 'iconsminds-conference', title: 'Clientes', count: 0 },
        { icon: 'iconsminds-basket-coins', title: 'Vendas', count: 0 },
        { icon: 'iconsminds-arrow-refresh', title: 'Médias', count: 0 },
        { icon: 'iconsminds-heart', title: 'Satisfação', count: 10 },
    ];

    return (
        <div className="icon-cards-row">
            <div className="glide dashboard-numbers">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {cardsData.map((card, index) => (
                            <DashboardCard key={index} {...card} />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DashboardCards;
