import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Button, Card, Form, Image, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faMoneyBill, faMoneyBillTransfer, faTicket, faUserPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import InputMask from 'react-input-mask';
import logo from '../../assets/logo.png'
import carrinho from '../../assets/buttons/carrinho.png'
import axios from 'axios'
import CadastroCliente from '../../components/modelsGenericos/CadastroCliente'
import EditarCliente from '../../components/modelsGenericos/EditarCliente'
import VoucherCliente from '../../components/modelsGenericos/VoucherCliente'
import fetchCashback from '../../services/configuracao/cashback'
import fetchClientes from '../../services/clientes/previsualizacao'
import { format } from 'date-fns';
import adicionar from '../../services/cashback/adicionar'
import resgatar from '../../services/cashback/resgatar'
import compraAddResgatar from '../../services/cashback/add-resgatar'
import Porco from '../../assets/buttons/porco.png'
import FotoPerfil from '../../assets/foto-perfil.webp'
import Resgatar from '../../assets/buttons/resgatar.png'
import Voucher from '../../assets/buttons/voucher.png'
import Cadastrar from '../../assets/buttons/cadastrar.png'
import Historico from '../../assets/buttons/historico.png'
import Sair from '../../assets/buttons/sair.png'
import "./App.css";
import Avatar from '../../components/Avatar/Avatar'
import NavbarComp from '../../components/Estrutura/NavbarCaixa';
import swal from 'sweetalert';
import { faTrash, faGift, faStar, shoppingBag } from '@fortawesome/free-solid-svg-icons';
import { CalculatorIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import "../../components/Estrutura/iconsmind-s/css/iconsminds.css";
import aniversario from '../../assets/aniversario.jpg'

// import CurrencyInputField from 'react-currency-input-field';

const Caixa = () => {


   


    const initialClienteData = {
        cpf: '',
        valor: '',
        codigo_venda: '',
        numeroVenda: '',
        valorNumber: 0,
    };
    const [cashbackConfig, setCashbackConfig] = useState({
        ativar: false,
        percentual: '',
        centavos: false,
        minimo: '',
        limite: '',
    })
    const [pontosAtuaisCliente, setPontosAtuaisCliente] = useState({
        pontos: 0,
        cashback: 0,
    });

    const [clienteData, setClienteData] = useState(initialClienteData);
    const [showCadastro, setShowCadastro] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [showVoucher, setShowVoucher] = useState(false);
    const [showHistorico, setShowHistorico] = useState(false);
    const [preVisualizacao, setPreVisualizacaoCaixa] = useState();
    const [showNotificationCompra, setShowNotificationCompra] = useState(false);
    const [historicos, setHistoricos] = useState([]);
    const [tipoFaturaSelecionado, setTipoFaturaSelecionado] = useState(''); // Novo estado
    const [numeroVenda, setNumeroVenda] = useState(''); // Novo estado

   
  const handleAlertaNumeroVenda = () => {
    swal({
      title: "Preencha todos os campos!",
      text: "Você só pode pontuar com todos os campos preenchidos!",
      icon: "warning",
      button: "Entendido!",
      dangerMode: true,
    });
  };
    useEffect(() => {
        async function fetchData() {
            try {
                const cashbackConfigAtual = await fetchCashback();
                setCashbackConfig(() => cashbackConfigAtual);
            } catch (error) {
                console.error('Erro ao buscar configuração de cashback:', error);
            }
        }

        fetchData(); // Chama a função assíncrona imediatamente

    }, []);

    useEffect(() => {
        // Remove caracteres não numéricos do CPF
        const numericCPF = clienteData.cpf.replace(/\D/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (numericCPF.length === 11) {
            fetchClientes(
                clienteData.cpf,
                cashbackConfig,
                setPontosAtuaisCliente,
                setPreVisualizacaoCaixa,
                showCadastroModal,
                showEditarModal
            );
        }
        else {
            setPreVisualizacaoCaixa('')
            setPontosAtuaisCliente({
                pontos: 0,
                cashback: 0,
            })
        }
    }, [clienteData.cpf]);

    const handleInputChange = (e) => {
        var { name, value } = e.target;
        if (name === "valor") {
            var valorString = String(value).replace("R$", "").trim();
            var valorNumber = parseFloat(valorString.replace(/\./g, "").replace(",", "."));
            setClienteData(prevData => ({
                ...prevData,
                valorNumber: valorNumber,
            }));
        }
        setClienteData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };


    const handleTipoFaturaChange = (e) => {
        setTipoFaturaSelecionado(e.target.value); // Atualiza estado de Tipo de Fatura
    };

    const handleNumeroVendaChange = (e) => {
        setNumeroVenda(e.target.value); // Atualiza estado de Número de Venda
    };

    var showCadastroModal = () => {
        setShowCadastro(true);
    }

    var showEditarModal = () => {
        setShowEditar(true);
    }
    var showVoucherModal = () => {
        setShowVoucher(true);
    }

    const fetchHistoricos = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };
            var cpfTratado = clienteData.cpf
            var dados = {
                "cpf": cpfTratado,
            }
            const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/historico/movimentacao`, dados, { headers }); // Substitua com a URL correta
            setHistoricos(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados do histórico:', error);
        }
    };

    var showHistoricoModal = () => {
        if(clienteData.cpf){
            fetchHistoricos();
            setShowHistorico(true);
        }else{
            alert("Digite um cpf valido")
        }
       
    }

    const handleClose = () => {
        setShowNotificationCompra(false);
        setShowCadastro(false);
        setShowEditar(false);
        setShowVoucher(false);
        setShowHistorico(false);
    };

    const handleVoltar = () => {
        if (sessionStorage.getItem('role') === "1") {
            window.location.href = '/'
        } else {
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = '/'
        }
    };

    function mascaraMoeda(event) {
        const onlyDigits = event.target.value
            .split("")
            .filter(s => /\d/.test(s))
            .join("")
            .padStart(3, "0")
        const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
        event.target.value = maskCurrency(digitsFloat)
    }

    function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(valor)
    }

    const handleAdd = async () => {
        var response = await adicionar(clienteData)
        
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
        handleClose();
    };

    const handleResgatePontos = async (event) => {
        event.preventDefault();
        var response = await resgatar(clienteData)
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
    };

    const handleCashbackReducao = async () => {
        var response = await compraAddResgatar(clienteData)
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
        handleClose();
    };

    const handleLimparCaixa = () => {
        setClienteData(initialClienteData); // Redefine os valores do cliente para o estado inicial
        setPontosAtuaisCliente({
            pontos: 0,
            cashback: 0,
        }); // Redefine os pontos e cashback
        setPreVisualizacaoCaixa(''); // Limpa a pré-visualização
        setNumeroVenda(''); // Limpa o número de venda
        setTipoFaturaSelecionado(''); // Limpa o tipo de fatura selecionado
    };

    const handleCPFChange = async (event) => {
        const cpf = event.target.value;

        if (cpf.length === 14) {
            
            try {
                const token = sessionStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                };

                const response = await axios.get(
                    `${process.env.REACT_APP_URLBK}/v-1/clientes/verificacao_pontos`,
                    { headers }
                );

                const response_niver = await axios.get(
                    `${process.env.REACT_APP_URLBK}/v-1/clientes/verificacao_pontos`,
                    { headers }
                );

                // Aqui você pode tratar a resposta do servidor
                const clienteInfo = response.data;

                //tratar responsta da api niver
                const niver = response_niver.data;
                console.log(niver);

                
                // Atualizar os dados do clienteData com as informações recebidas
                setClienteData((prevData) => ({
                    ...prevData,
                    pontos: clienteInfo.pontos,
                    cashback: clienteInfo.cashback,
                }));

            } catch (error) {
                console.error('Erro ao buscar informações do cliente:', error);
            }

        } else {
            // Limpar os dados do clienteData caso o CPF não tenha 14 dígitos
            setClienteData((prevData) => ({
                ...prevData,
                pontos: '',
                cashback: '',
            }));
        }
    };


  return (

    <>
    <div className="page-container">
      {/* Barra Superior */}
      <NavbarComp/>
     <br></br>
     <br></br>
     <br></br>
 
    <div className="content">

        <div className="nfc-section">

         <div className='caixa-container-logo'>
         <i className="iconsminds-shop-4 icone-grafico-caixa" ></i>
         </div>
  
          <Form className="caixa-form">
   
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
          <InputMask
            mask="999.999.999-99"
            maskChar=" "
            className="form-control input"
            name="cpf"
            placeholder="Digite o CPF do cliente"
            value={clienteData.cpf}
            onChange={handleInputChange}
            onInput={handleCPFChange}
            required
          />
        </Form.Group>

        {/* Campo de Valor */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
          <Form.Control
            type="text"
            placeholder="R$ 0,00"
            className="input"
            onInput={(event) => mascaraMoeda(event)}
            name="valor"
            value={clienteData.valor}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Select
                                    aria-label="Default select example"
                                    className="input"
                                    value={tipoFaturaSelecionado}
                                    onChange={handleTipoFaturaChange}
                                    required
                                >
                                    <option value="">Tipo de Fatura</option>
                                    <option value="1">Nota Fiscal</option>
                                    <option value="2">Recibo</option>
                                    <option value="3">Numero de Venda</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Control
                                    type="text"
                                    placeholder="Número de venda"
                                    className="input"
                                    value={numeroVenda}
                                    onChange={handleNumeroVendaChange}
                                    required
                                />
                            </Form.Group>



      </Form>

      <div className="actions">

       

         {/*<button onClick={() => showVoucherModal(true)} className="action-btn orange">
        <FontAwesomeIcon icon={faGift} /> Resgatar Voucher
        </button>
*/}
    </div>
    
    </div>

        {/* Área de Caixa Livre */}
        <div className="caixa">
          {preVisualizacao ? (
        <p>{preVisualizacao}</p>
    ) : (
        <h3>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        Insira o CPF para visualizar as informações do cliente.</h3>
    )}
                   {/* Botão Pontuar Condicional */}
    {clienteData.cpf && clienteData.valor && tipoFaturaSelecionado && numeroVenda ? (
        <button onClick={() => setShowNotificationCompra(true)} className="action-btn blue">
        <FontAwesomeIcon icon={faStar} /> Pontuar Cliente
    </button>
    ) : clienteData.cpf && clienteData.valor ? (
<button onClick={handleAlertaNumeroVenda} className="action-btn blue">
<FontAwesomeIcon icon={faStar} /> Pontuar Cliente
</button>
    ) : null }

{clienteData.cpf && clienteData.valor ? (
      <button className="action-btn blue botao-cancelar" onClick={handleLimparCaixa}>
        <FontAwesomeIcon icon={faTrash} /> Cancelar
        </button>
    ) : null}

{preVisualizacao ? (
      <button className="action-btn blue botao-cancelar" onClick={setShowEditar}>
        <FontAwesomeIcon icon={faEdit} /> Editar Cliente
        </button>
    ) : null}

    
        </div>
      </div>
     
      {/* Botões de Ação */}
      
    </div>


    <Modal show={showNotificationCompra} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Utilizar o Cashback & Fatura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>
              <strong>Deseja utilizar os pontos do Cliente Prime?</strong> <br />
              <strong>Pontos:</strong> {(Math.trunc(pontosAtuaisCliente.pontos * 100) / 100).toFixed(2)} <br />
              <strong>Cashback:</strong> R$ {(Math.trunc(pontosAtuaisCliente.cashback * 100) / 100).toFixed(2)} <br />
              <strong>Valor da compra:</strong> {clienteData.valor} <br />
              <strong>Valor Final será:</strong> {Math.max(0, clienteData.valorNumber - pontosAtuaisCliente.cashback).toFixed(2)}
            </h5>
          </div>

          <hr />
          <div>
            <h5><strong>Detalhes da Fatura</strong></h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Tipo de Fatura</th>
                  <th>Número de Venda</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{tipoFaturaSelecionado}</td>
                  <td>{numeroVenda}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <Button onClick={() => handleCashbackReducao()}>Utilizar Cashback</Button>{'  '}
          <Button variant="danger" onClick={() => handleAdd()}>Não utilizar Cashback</Button>
        </Modal.Body>
      </Modal>





<Modal show={showCadastro} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Cadastro Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>
    <CadastroCliente handleClose={handleClose} />
</Modal.Body>
</Modal>

<Modal show={showEditar} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Editar Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>
    <EditarCliente cpf={clienteData.cpf}  handleClose={handleClose} />
</Modal.Body>
</Modal>

<Modal show={showVoucher} onHide={handleClose} >
<Modal.Header closeButton>
    <Modal.Title>Resgatar Voucher</Modal.Title>
</Modal.Header>
<Modal.Body>
    <VoucherCliente handleClose={handleClose} />
</Modal.Body>
</Modal>
<Modal show={showHistorico} onHide={handleClose} className='modal-full'>
<Modal.Header closeButton>
    <Modal.Title>Historico Cliente</Modal.Title>
</Modal.Header>
<Modal.Body>

    <Table striped bordered hover>
        <thead>
            <tr>
                <th>Consumidor</th>
                <th>Ação</th>
                <th>Quantidade</th>
                <th>Data</th>
                <th>Agente</th>
                <th>Local</th>
            </tr>
        </thead>
        <tbody>
            {historicos.map((historico) => (
                <tr key={historico.id}>
                    <td>{historico.cliente}</td>
                    <td className={`tipo-${historico.tipo.toLowerCase().replace(' ', '-')}`}>{historico.tipo}</td>
                    <td>{historico.pontos}</td>
                    <td>{format(new Date(historico.at_create), 'dd/MM/yyyy HH:mm:ss')}</td>
                    <td> {historico.usuario}</td>
                    <td>{historico.local}</td>
                    
                </tr>
            ))}
        </tbody>
    </Table>
</Modal.Body>
</Modal>


<button
      onClick={setShowCadastro}
      style={{
        position: 'fixed',
        width: '60px',
        height: '60px',
        bottom: '40px',
        right: '40px',
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: '50%',
        border: 'none',
        textAlign: 'center',
        fontSize: '30px',
        boxShadow: '1px 1px 2px #888',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faUserPlus} />
    </button>
</>
  );
};

export default Caixa;
